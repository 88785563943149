<template>
	<div class='a-timeline-box'>
		<p class='a-timeline-date-card' v-if='date'>{{date}}</p>
		<div class='a-timeline-wrap'>
			<div class='a-timeline-wrap-header-row'>
				<div class="a-timeline-wrap-header-cell">
					<div class="a-timeline-wrap-header-icon">
						<v-icon :icon='titleIcon' size='21' />
					</div>
					<p class="a-timeline-wrap-header-title">{{title}}</p>
				</div>
				<template v-if='!result'>
					<div class='a-timeline-wrap-header-cell mobile-hide'>
						<div class="a-timeline-button-add" @click='$emit("open-edit")'>
							{{$t("account.buttons.edit")}}
						</div>
						<div class="a-timeline-button-delete" @click='$emit("open-dialog")'>
							<v-icon icon='delete' size='18' />
						</div>
					</div>
					<div class='a-timeline-wrap-header-cell mobile-show'>
						<a-menu :items='items_menu' />
					</div>
				</template>
			</div>
			<div class='a-timeline-wrap-container'>
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			open: false
		}),
		props: ['date', 'title', 'titleIcon', 'result'],
		computed: {
			items_menu() {
				return [
					{name: i18n.t("account.buttons.edit"), callback: () => this.$emit("open-edit")},
					{name: i18n.t('account.buttons.remove'), callback: () => this.$emit("open-dialog"), color: 'error--text'}
				]
			}
		},
		methods: {
			closeSelect() {
				this.open = false
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline {
		&-date-card {
			font-size: 18px;
			font-weight: 700;
			line-height: 1.5;
			background-color: $white;
			margin-top: 40px;
			text-transform: capitalize;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 22px;
				margin-top: 48px;
			}
		}
		&-button {
			&-add {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
				padding: 4px 10px;
				border: 1px solid rgba($black, .1);
				border-radius: 4px;
				cursor: pointer;
				transition: border $transition;
				&:hover {
					border: 1px solid rgba($black, .5);
				}
			}
			&-delete {
				padding: 3px;
				margin-left: 16px;
				color: rgba($cyprus, .4);
				cursor: pointer;
				transition: color $transition;
				&:hover {
					color: rgba($cyprus, .8);
				}
			}
		}
		&-wrap {
			&-header {
				&-row {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
				}
				&-cell {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
				}
				&-icon {
					padding: 4px 0;
					flex-shrink: 0;
					background-color: $white;
					color: $green-persian;
				}
				&-title {
					font-size: 15px;
					line-height: 1.5;
					font-weight: 400;
					margin-left: 16px;
					margin-right: 16px;
					@include minw( $grid-breakpoints-xs ) {
						font-size: 18px;
						font-weight: 700;
						margin-left: 24px;
					}
					@include maxw( $grid-breakpoints-xs - 1 ) {
						margin-top: 2px;
					}
				}
			}
			&-container {
				padding-top: 15px;
				@include minw( $grid-breakpoints-xs ) {
					padding-left: 40px;
				}
			}
		}
	}
</style>
