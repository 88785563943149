<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>{{$t('account.details.hotels.title')}}</p>
							</div>
							<div class="col-auto">
								<div class='a-card-details-header-button' @click='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
						</div>
					</div>
					<div class='a-card-details-content'>
						<div class='a-hotel-card-details-wrap'>
							<div class='a-hotel-card-details-header'>
								<div class="row">
									<div class="col">
										<ul class='a-hotel-stars-list' style='margin-bottom: 10px'>
											<li class='a-hotel-stars-item' v-for='(item, i) in card.categorySimpleCode' :key='i'>
												<v-icon icon='star' size='14' />
											</li>
										</ul>
										<p class='a-hotel-card-details-title'>{{card.name}}</p>
										<div class='row'>
											<div class='col-auto'>
												<p class='a-hotel-card-details-descr'>{{$t('account.form.placeholder.dateFrom')}}</p>
												<p class='a-hotel-card-details-text' v-if='card.dateFrom'>{{$moment(card.dateFrom).format("dddd, MMM DD")}}</p>
											</div>
											<div class='col-auto'>
												<p class='a-hotel-card-details-descr'>{{$t('account.form.placeholder.dateTo')}}</p>
												<p class='a-hotel-card-details-text' v-if='card.dateTo'>{{$moment(card.dateTo).format("dddd, MMM DD")}}</p>
											</div>
										</div>
									</div>
									<!-- <div class="col-auto">
										<div class='a-hotel-card-details-logo'>
											<p>logo</p>
										</div>
									</div> -->
								</div>
							</div>

							<div class='a-hotel-card-details-content'>
								<p class='a-hotel-card-details-descr'>{{$t('account.details.hotels.selected_numbers')}}:</p>
								<div class='a-hotel-card-details-item'>
									<div class="row">
										<div class="col">
											<p class='a-hotel-card-details-item-title'>
												<!-- <span>4х</span> -->
												{{card.roomName}}
											</p>
											<span class='a-hotel-card-details-text'>{{card.boardName}}</span>
										</div>
										<div class="col-auto">
											<p class='a-hotel-card-details-text'>{{$helpers.amount(card.price.total.amount, card.price.total.currency)}}</p>
										</div>
									</div>
								</div>
							</div>

							<div class='a-hotel-card-details-footer'>
								<div class="row">
									<div class="col">
										<p class='a-hotel-card-details-footer-title'>{{$t('account.details.hotels.total')}}</p>
									</div>
									<div class="col-auto">
										<p class='a-hotel-card-details-footer-title'>{{$helpers.amount(card.price.total.amount, card.price.total.currency)}}</p>
									</div>
								</div>

								<template v-if='card.rateComments'>
									<div class="divider"></div>

									<div class="row">
										<div class="col">
											<p class='a-hotel-card-details-footer-text'>{{$t('account.details.hotels.tariff_details')}}</p>
										</div>
									</div>
									<p class='a-hotel-card-details-footer-descr' v-html='card.rateComments'></p>
								</template>
							</div>

							<div class='a-hotel-card-details-choose' v-if='card.selectedTransfer'>
								<div class='a-hotel-card-details-choose-item'>
									<p class='a-hotel-card-details-footer-title'>{{$t('account.details.hotels.transfer')}}</p>
									<div class="row a-hotel-card-details-choose-row">
										<div class="col">
											<p class='a-hotel-card-details-text'>{{card.selectedTransfer.name}}</p>
										</div>
										<div class="col-auto">
											<p class='a-hotel-card-details-text'>{{$helpers.amount(card.selectedTransfer.price.total.amount, card.selectedTransfer.price.total.currency)}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class='a-card-details-footer'>
						<div class='a-card-details-footer__divider'>
							<div class='divider' />
						</div>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-footer-title'>{{$helpers.amount(card.price.total.amount, card.price.total.currency)}}</p>
								<p class='a-card-details-footer-descr'>{{$t('account.the_price_is_generally_for')}} {{$moment(card.dateTo).diff($moment(card.dateFrom), 'days')}} {{$helpers.declOfNum($moment(card.dateTo).diff($moment(card.dateFrom), 'days'), $t('account.declOfNum.nights'))}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		props: ['value', 'card'],
		methods: {
			closeDialog() {
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		},
		beforeDestroy() {
			enablePageScroll();
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-hotel-card-details {
		&-wrap {
			@include minw( $grid-breakpoints-xs ) {
				border-radius: 10px;
				box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
				margin-bottom: 35px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-bottom: 35px;
			}
		}
		&-header {
			padding: 20px 0;
			border-bottom: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px * .8 35px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 30px 35px 25px;
			}
		}
		&-content {
			padding: 20px 0;
			@include minw( $grid-breakpoints-xs ) {
				padding: 20px * .8 35px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 20px 35px 25px;
			}
		}
		&-title {
			font-size: 22px * .8;
			font-weight: 700;
			margin-bottom: 25px * .8;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 22px;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-bottom: 25px;
			}
		}
		&-descr {
			font-size: 13px;
			font-weight: 400;
			color: rgba($black, .6);
		}
		&-text {
			font-size: 16px * .9;
			font-weight: 400;
			text-transform: capitalize;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px;
			}
		}
		&-item {
			margin-top: 10px * .8;
			@include minw( $grid-breakpoints-md ) {
				margin-top: 10px;
			}
			&:not(:last-child) {
				margin-bottom: 25px * .8;
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 25px;
				}
			}
			&-title {
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 10px * .8;
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 10px;
				}
			}
		}
		&-footer {
			padding: 15px 0;
			border-top: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding: 15px * .8 35px * .8 30px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 15px 35px 30px;
			}
			.divider {
				margin-bottom: 20px;
			}
			&-title {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 15px;
			}
			&-text {
				font-size: 13px;
				font-weight: 400;
				line-height: 1.5;
				color: $orange;
				margin-bottom: 5px;
			}
			&-descr {
				font-size: 12px;
				font-weight: 400;
				line-height: 1.5;
				color: rgba($black, .6);
			}
		}
		&-choose {
			padding: 20px 0;
			border-top: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px * .8 35px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 30px 35px;
			}
			&-item {
				max-width: 430px;
				&:not(:last-child) {
					margin-bottom: 35px;
				}
			}
			&-row {
				margin-bottom: 15px;
			}
		}
	}


	.a-hotel {
		&-stars {
			&-list {
				display: flex;
				margin-bottom: 5px;
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 20px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 20px;
				}
			}
			&-item {
				margin: 0 3px;
				color: rgba($cyprus, .5);
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin: 0 1px;
					.app-icon {
						width: 10px !important;
						height: 10px !important;
					}
				}
			}
		}
	}
</style>
