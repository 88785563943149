<template>
	<div class="a-timeline-flights">
		<div v-for='(segment, i) in itinerary.Segments' :key='i'>
			<div class='a-timeline-item'>
				<div class='a-timeline-flights-content'>
					<a-flight-card-item
						:item='itemOrigin'
						:itinerary='itinerary'
						:segment='segment'
					/>
				</div>
				<div class='a-timeline-flights-item-footer' v-if='!result'>
					<div class="a-timeline-flights-item-footer-details">
						<p class='app-link green--text' @click='card_details = true'>{{$t('account.buttons.read_more')}}</p>
					</div>
				</div>
			</div>

			<div class='a-timeline-flights-stop' v-if='itinerary.Segments[i + 1]'>
				<div class="a-timeline-flights-stop-line"></div>
				<div class='a-timeline-flights-stop-icon'>
					<v-icon icon='stop' size='22' />
				</div>
				<div class='a-timeline-flights-stop-content'>
					<p class='a-timeline-flights-stop-number'>{{$helpers.timeName($moment(itinerary.Segments[i + 1].DepartureDT).diff($moment(segment.ArrivalDT), "minutes"))}}</p>
					<p class='a-timeline-flights-stop-title'>{{$t('account.details.flights.stop_at')}} {{segment.DestinationLocName}}</p>
				</div>
			</div>
		</div>
		<a-timeline-markup
			:item='item'
			:price='item.price'
			:title='title'
			:titleIcon='titleIcon'
			:result='result'
			@update-order='$emit("update-order", $event)'
			v-if='item.price.userMargin'
		/>

		<trips-card-details v-model='card_details' :card='item' :itemOrigin='itemOrigin' />
	</div>
</template>

<script>
	import AFlightCardItem from '@/views/account/search/flights/card/item';
	import ATimelineMarkup from '@/views/account/itinerary/markup';
	import TripsCardDetails from '@/views/account/itinerary/timeline/flights-details';

	export default {
		data: () => ({
			card_details: false,
		}),
		props: ['item', 'itemOrigin', 'itinerary', 'title', 'titleIcon', 'result'],
		components: {
			AFlightCardItem,
			ATimelineMarkup,
			TripsCardDetails
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-flights {
		&-item {
			&-footer {
				border-top: 1px solid rgba($black, .1);
				&-details {
					padding: 10px 15px;
					@include minw( $grid-breakpoints-xs ) {
						padding: 8px 40px;
						min-height: 35px;
					}
				}
			}
		}
		&-content {
			padding: 15px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 35px * .6 30px * .6 25px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 35px * .8 30px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 35px 30px 25px;
			}
		}
		&-stop {
			display: flex;
			align-items: flex-start;
			color: rgba($orange, .8);
			padding: 20px 0 20px 16px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px 0 30px 35px;
			}
			&-line {
				position: absolute;
				top: 0;
				bottom: 0px;
				width: 2px;
				left: 23px;
				background-color: rgba($cyprus, .15);
				mask-image: url("../../../../assets/line.svg");
				mask-repeat: repeat-y;
				@include minw( $grid-breakpoints-xs ) {
					left: 45px;
				}
			}
			&-icon {
				margin-top: -1px;
				flex-shrink: 0;
				margin-right: 20px;
				background-color: $white;
				@include minw( $grid-breakpoints-xs ) {
					margin-right: 45px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					.app-icon {
						width: 16px !important;
						height: 16px !important;
					}
				}
			}
			&-content {
				display: flex;
				align-items: baseline;
			}
			&-number,
			&-title {
				font-size: 13px;
				font-weight: 400;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 16px;
				}
			}
			&-number {
				flex-shrink: 0;
				margin-right: 15px;
				@include minw( $grid-breakpoints-xs ) {
					margin-right: 26px;
				}
			}
		}
	}
</style>
