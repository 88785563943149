<template>
	<div class="a-timeline-insurance">
		<div class='a-timeline-item'>
			<div class='a-timeline-insurance-wrap'>
				<p class='a-timeline-insurance-title'>{{title}}</p>
				<div class='a-timeline-insurance-row' v-for='(visa, i) in Object.keys(item.visas)' :key='i'>
					<div class='a-timeline-insurance-item' v-for='(el, i) in item.visas[visa]' :key='i'>
						<div class='a-timeline-insurance-item-col'>
							<p class='a-timeline-insurance-item-title'>{{el.name}}
								<template v-if='Number(el.price.total.amount) > 0'>
									<span class='dot'>•</span> <strong>{{$helpers.amount(el.price.total.amount, el.price.total.currency)}}</strong>
								</template>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<a-timeline-markup
			:item='item'
			:price='item.price'
			:title='title'
			:titleIcon='icon'
			:result='result'
			@update-order='$emit("update-order", $event)'
		/>
	</div>
</template>

<script>
	import ATimelineMarkup from '@/views/account/itinerary/markup';

	export default {
		props: ['item', 'title', 'icon', 'result'],
		components: {
			ATimelineMarkup
		}
	}
</script>
