<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>{{$t('account.details.flights.title')}}</p>
							</div>
							<div class="col-auto">
								<div class='a-card-details-header-button' @click='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
						</div>
					</div>
					<div class='a-card-details-content'>
						<div class='a-card-details-box' v-for='(itinerary, i) in card.itineraries' :key='i'>
							<p class='a-card-details-date'>{{$moment(itinerary.DepartureDT).format("ddd, DD MMM")}}</p>

							<div class='a-timeline-item' style='margin-bottom: 15px'>
								<div class='a-timeline-flights-content'>
									<template v-if='!showDetails[i]'>
										<a-flight-card-item
											:item='itemOrigin'
											:itinerary='itinerary'
											:detailsButton='true'
											@open-details='showDetails = {...showDetails, [i]: true}'
										/>
									</template>
									<template v-else>
										<div v-for='(item, i) in itinerary.Segments' :key='i'>
											<div class='a-card-details-item'>
												<div class='a-card-details-item-header'>
													<div class='a-card-details-item-logo'>

														<img class='a-card-details-item-logo-img' :src="`https://api.bravo-travel.online/api${item.AirlineLogo}`" alt="">
													</div>
													<div class='a-card-details-item-header-content'>
														<div class='row'>
															<div class="col">
																<p class='a-card-details-item-title'>{{item.AirlineName}}</p>
															</div>
															<div class="col-auto">
																<span class='a-card-details-item-chip'>{{item.MarketingAirline}}{{item.AirFlightNumber}}</span>
															</div>
														</div>
														<p class='a-card-details-item-descr'>{{$t('account.details.flights.operated')}} {{item.AirlineName}}</p>
														<p class='a-card-details-item-descr'>{{items_airClass.find(el => el.id == item.Cabin) ? items_airClass.find(el => el.id == item.Cabin).name : item.Cabin}}</p>
													</div>
												</div>

												<card-details-direction
													type='departure'
													:time='`${$moment(item.DepartureDT).format("HH:mm")}`'
													:title='`${item.OriginLocName} (${item.OriginLoc})`'
													:date='$moment(item.DepartureDT).format("ddd, DD MMM")'
													:duration='$helpers.timeName(item.ElapsedTime)'
												/>

												<card-details-direction
													type='arrivale'
													:time='`${$moment(item.ArrivalDT).format("HH:mm")}`'
													:title='`${item.DestinationLocName} (${item.DestinationLoc})`'
													:date='$moment(item.ArrivalDT).format("ddd, DD MMM")'
													:diff-days='$helpers.diffDays(item.DepartureDT, item.ArrivalDT)'
												/>

												<div class='a-card-details-direction-info' v-if='item.SeatsRemaining > 0 && item.SeatsRemaining <= 5'>
													<div class='a-card-details-item-logo'>
														<v-icon icon='seat' size='18' />
													</div>
													<div>
														<p class='a-card-details-direction-info-text'>{{$t('account.details.flights.remains')}} {{item.SeatsRemaining}} {{$helpers.declOfNum(item.SeatsRemaining, $t('account.declOfNum.places'))}} {{$t('account.details.flights.on_this_flight')}}</p>
													</div>
												</div>
											</div>

											<div class='a-card-details-item' v-if='itinerary.Segments[i + 1]'>
												<card-details-direction
													type='stop'
													:time='$helpers.timeName($moment(itinerary.Segments[i + 1].DepartureDT).diff($moment(item.ArrivalDT), "minutes"))'
													:title="`${$t('account.details.flights.stop_at')} ${item.DestinationLocName}`"
													custom-color='rgba(255, 153, 0, .8)'
												/>
											</div>
										</div>
										<div class="row desktop-hide">
											<div class="col"></div>
											<div class="col-auto">
												<span class='app-link green--text' @click='showDetails = {...showDetails, [i]: false}'>{{$t('account.details.flights.hide_details')}}</span>
											</div>
										</div>
									</template>
								</div>
								<div class='a-timeline-flights-item-footer desktop-show'>
									<div class="a-timeline-flights-item-footer-details">
										<p class='app-link green--text text-center' @click='showDetails = {...showDetails, [i]: true}' v-if='!showDetails[i]'>{{$t('account.details.flights.show_details')}}</p>
										<p class='app-link green--text text-center' @click='showDetails = {...showDetails, [i]: false}' v-else>{{$t('account.details.flights.hide_details')}}</p>
									</div>
								</div>
							</div>
						</div>

						<div class='a-card-details-box' style='margin-bottom: 15px' v-if='card.type === "flight"'>
							<p class='a-card-details-date'>{{$t('account.details.flights.tariff_conditions')}}</p>

							<div class='a-card-details-info'>
								<div v-for='(itinerary, i) in card.itineraries' :key='i'>
									<div class='a-card-details-info-item'>
										<div class='a-card-details-info-header'>
											<div class='a-card-details-info-header-logo'>
												<img class='a-card-details-info-header-logo-img' :src="`https://api.bravo-travel.online/api${card.origin.ValidCarrierLogo}`" alt="">
											</div>
											<div class='a-card-details-info-header-content'>
												<p class='a-card-details-info-header-title'>{{itinerary.OriginLocName}}</p>
												<v-icon icon='air' size='14' class='a-card-details-info-header-icon' />
												<p class='a-card-details-info-header-title'>{{itinerary.DestinationLocName}}</p>
											</div>
											<div class='a-card-details-info-header-action' v-if='itinerary.FareOptionsDetails.length > 0'>
												<div @click='openModal = true, item = {airClass: itinerary.Segments[0].Cabin, optionsDetails: itinerary.FareOptionsDetails, details: true}'>
													<v-icon icon='info' size='20' class='a-card-details-info-header-action-icon' />
												</div>
											</div>
										</div>

										<div class='a-card-details-info-content'>
											<div class="row">
												<div class="col-6">
													<p class='a-card-details-info-content-title'>
														<span>{{items_airClass.find(el => el.id == itinerary.Segments[0].Cabin) ? items_airClass.find(el => el.id == itinerary.Segments[0].Cabin).name : itinerary.Segments[0].Cabin}}</span>
													</p>
												</div>
												<div class="col-6">
													<template v-if='itinerary.FareOptions.length > 0'>
														<div class='a-card-details-info-content-item' :style='`${option.isAvailable ? "color: #00AF85": ""}`' v-for='(option, i) in itinerary.FareOptions' :key='i'>
															<v-icon :icon='option.icon ? option.icon : "info"' size='14' class='a-card-details-info-content-item-icon' />
															<p class='a-card-details-info-content-item-text'>{{option.name}}</p>
														</div>
													</template>
													<template v-else>
														<div class='a-card-details-info-content-item'>
															<v-icon icon='info' size='14' class='a-card-details-info-content-item-icon' />
															<p class='a-card-details-info-content-item-text'>{{$t('account.details.flights.tariff_conditions_no_info')}}</p>
														</div>
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class='a-card-details-box a-card-details-tourist' v-if='card.type === "flight"'>
							<p class='a-card-details-date'>{{$t("account.itinerary.timeline.flights.tourists")}}</p>

							<div v-for='(passenger, i) in card.passengers' :key='i'>
								<div class='a-card-details-info' style='margin-bottom: 20px'>
									<div class='a-card-details-info-item'>
										<a-user
											:user='passenger'
											:subTitle="`${passenger.type === 'children' ? (passenger.age === 0 ? $t('account.declOfNum.up_to_1_year') : `${passenger.age} ${$helpers.declOfNum(passenger.age, $t('account.declOfNum.years'))}`) : ''}`"
											:index='i'
										/>

										<p class='a-sub-heading'>{{$t("account.itinerary.timeline.flights.additional_luggage")}}</p>

										<div class='a-card-details-baggage-item' v-for='(itinerary, i) in card.itineraries' :key='i'>
											<div class='a-card-details-info-header mb-0'>
												<div class='a-card-details-info-header-logo'>
													<img class='a-card-details-info-header-logo-img' :src="`https://api.bravo-travel.online/api${card.origin.ValidCarrierLogo}`" alt="">
												</div>
												<div class='a-card-details-info-header-content'>
													<p class='a-card-details-info-header-title'>{{itinerary.OriginLocName}}</p>
													<v-icon icon='air' size='14' class='a-card-details-info-header-icon' />
													<p class='a-card-details-info-header-title'>{{itinerary.DestinationLocName}}</p>
												</div>
											</div>
											<div class='a-card-details-info-header'>
												<div class='a-card-details-info-header-logo' style='height: auto'></div>
												<div class='a-card-details-info-header-content'>
													<template v-if='findAncillariesBaggage(passenger, itinerary)'>
														<p class='a-text-with-icon'>
															<v-icon icon='baggage' size='16'></v-icon>
															{{card.ancillaries.find(el => el.passengerId === passenger.id).name}}
														</p>
													</template>
													<template v-else>
														<p class='a-text-with-icon'>{{$t("account.itinerary.timeline.flights.no_additional_luggage")}}</p>
													</template>
												</div>
											</div>
										</div>
									</div>
									<div class='a-card-details-loyalty-program'>
										<div class="row">
											<div class="divider"></div>
										</div>
										<div class="a-card-details-loyalty-program-content">
											<p class='a-sub-heading' :style='!passenger.selectedLoyaltyPrograms ? "margin-bottom: 5px;" : ""'>{{$t("account.itinerary.timeline.flights.loyalty_program")}}</p>
											<template v-if='passenger.selectedLoyaltyPrograms && passenger.selectedLoyaltyPrograms.length'>
												<div class='a-card-details-loyalty-program-descr'>
													<p class='a-text-with-icon'>
														<v-icon icon='check' size='10' />
														{{$t("account.itinerary.timeline.flights.add_loyalty_program")}}
													</p>
													<p class='a-card-details-loyalty-program-title' v-for='(item, i) in passenger.selectedLoyaltyPrograms' :key='i'>
														{{item.name}} {{item.number}}
													</p>
												</div>
											</template>
											<template v-else>
												<p class='a-text-with-icon'>{{$t("account.itinerary.timeline.flights.no_loyalty_program_added")}}</p>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class='a-card-details-special' v-if='card.type === "flight"'>
							<a-offer-special-request
								:userComment='userComment'
								@input='($event) => userComment = $event'
							/>
						</div>

					</div>
					<div class='a-card-details-footer'>
						<div class='a-card-details-footer__divider'>
							<div class='divider' />
						</div>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-footer-title'>{{$helpers.amount(card.price.total.amount, card.price.total.currency)}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>

		<a-dialog-branded-fares
			v-model='openModal'
			@close-dialog='openModal = false, item = null'
			:card='item'
		/>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import CardDetailsDirection from '@/views/account/details/flights-direction';
	import { items_airClass } from '@/helpers/items';
	import ADialogBrandedFares from '@/views/account/offers/flights/dialogBrandedFares';
	import AFlightCardItem from '@/views/account/search/flights/card/item';
	import AOfferSpecialRequest from '@/views/account/offers/components/special-request';

	export default {
		data: () => ({
			details: [],
			openModal: false,
			item: null,
			showDetails: {},
			userComment: ''
		}),
		props: ['value', 'card', 'itemOrigin'],
		components: {
			CardDetailsDirection,
			ADialogBrandedFares,
			AFlightCardItem,
			AOfferSpecialRequest
		},
		computed: {
			items_airClass() {
				return items_airClass()
			}
		},
		methods: {
			findAncillariesBaggage(passenger, itinerary) {
				return this.card.ancillaries.length && this.card.ancillaries.find(el => el.passengerId === passenger.id && el.originAirport === itinerary.OriginLoc && el.type === "baggage")
			},
			closeDialog() {
				if(this.openModal) return;
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				if(this.card) {
					this.userComment = this.card.userComment;
				}
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-details-tourist {
		.a-user {
			margin-bottom: 30px;
		}
		.a-sub-heading {
			margin-bottom: 15px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 15px;
				font-weight: 400;
			}
		}
	}
	.a-card-details-baggage-item {
		&:not(:last-child) {
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 30px;
			}
		}
	}
	.a-card-details-loyalty-program {
		.row {
			margin: 0 -10px;
			@include minw( $grid-breakpoints-xs ) {
				margin: 0 -24px;
			}
			@include minw( $grid-breakpoints-md ) {
				margin: 0 -30px;
			}
		}
		&-content {
			padding: 20px 0 15px;
		}
		&-descr {
			.a-text-with-icon {
				color: rgba($black, .8);
				.app-icon {
					color: $green-persian;
				}
			}
		}
		&-title {
			font-size: 14px;
			font-weight: 700;
			margin-top: 5px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px;
			}
		}
	}
	.a-card-details-special {
		margin-top: 30px;
	}
</style>
