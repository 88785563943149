<template>
	<div class='a-card-clock'>
		<div class="flex a-card-clock-item">
			<a-card-time
				:itinerary='itinerary'
				:segment='segment'
			/>

			<div class='mobile-hide'>
				<template v-if='detailsButton'>
					<span class='app-link green--text' @click='$emit("open-details")'>{{$t('account.details.flights.show_details')}}</span>
				</template>
				<template v-else>
					<div class="a-card-tariff-info">
						<div class='a-card-tariff-info-row' v-if='itinerary.Baggage.details'>
							<!-- <div class='a-card-tariff-info-col'>
								<div class='a-card-tariff-info-item personal' v-tooltip.top="$t('account.search.flights.passenger_personal_bag')">
									<v-icon icon='bag-personal' size='19' class='a-card-tariff-info-item-icon' />
									<span class='a-card-tariff-info-item-count'>2</span>
								</div>
							</div> -->
							<div class='a-card-tariff-info-col' v-if='itinerary.Baggage.details.handLuggageIncluded'>
								<div class='a-card-tariff-info-item luggage' v-tooltip.top="$t('account.search.flights.hand_luggage_of_the_passenger')">
									<v-icon icon='bag-luggage' size='23' class='a-card-tariff-info-item-icon' />
									<span class='a-card-tariff-info-item-count'>{{itinerary.Baggage.details.handLuggageWeight}}</span>
								</div>
							</div>
							<div class='a-card-tariff-info-col' v-if='itinerary.Baggage.details.baggageIncluded'>
								<div class='a-card-tariff-info-item checked' v-tooltip.top="$t('account.search.flights.checked_baggage_per_passenger')">
									<v-icon icon='bag-checked' size='19' class='a-card-tariff-info-item-icon' />
									<span class='a-card-tariff-info-item-count'>{{itinerary.Baggage.details.baggageWeight}}</span>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div class="flex">
			<div class='a-card-logo' style='height: auto'></div>
			<div class='a-card-info'>
				<div class="a-card-info-text">{{itinerary.Segments[0].AirlineName}} • {{items_airClass.find(el => el.id === itinerary.Segments[0].Cabin) ? items_airClass.find(el => el.id === itinerary.Segments[0].Cabin).name : itinerary.Segments[0].Cabin}}</div>
				<div class="a-card-tariff-info mobile-show">
					<div class='a-card-tariff-info-row' v-if='itinerary.Baggage.details'>
						<!-- <div class='a-card-tariff-info-col'>
							<div class='a-card-tariff-info-item personal' v-tooltip.top="$t('account.search.flights.passenger_personal_bag')">
								<v-icon icon='bag-personal' size='14' class='a-card-tariff-info-item-icon' />
								<span class='a-card-tariff-info-item-count'>2</span>
							</div>
						</div> -->
						<div class='a-card-tariff-info-col' v-if='itinerary.Baggage.details.handLuggageIncluded'>
							<div class='a-card-tariff-info-item luggage' v-tooltip.top="$t('account.search.flights.hand_luggage_of_the_passenger')">
								<v-icon icon='bag-luggage' size='18' class='a-card-tariff-info-item-icon' />
								<span class='a-card-tariff-info-item-count'>{{itinerary.Baggage.details.handLuggageWeight}}</span>
							</div>
						</div>
						<div class='a-card-tariff-info-col' v-if='itinerary.Baggage.details.baggageIncluded'>
							<div class='a-card-tariff-info-item checked' v-tooltip.top="$t('account.search.flights.checked_baggage_per_passenger')">
								<v-icon icon='bag-checked' size='14' class='a-card-tariff-info-item-icon' />
								<span class='a-card-tariff-info-item-count'>{{itinerary.Baggage.details.baggageWeight}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ACardTime from '@/views/account/search/flights/card/time';
	import { items_airClass } from '@/helpers/items';

	export default {
		computed: {
			items_airClass() {
				return items_airClass()
			}
		},
		props: ['item', 'itinerary', 'segment', 'detailsButton'],
		components: {
			ACardTime
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card {
		&-clock {
			flex: 1 1 100%;
			&-flex {
				@include minw($grid-breakpoints-xxs) {
					flex-shrink: 0;
				}
				@include minw($grid-breakpoints-sm) {
					margin-right: 12px;
				}
				@include maxw($grid-breakpoints-xs - 1) {
					flex: 1 1 100%;
					justify-content: space-between;
				}
				@include maxw($grid-breakpoints-xxs - 1) {
					flex-direction: column;
				}
			}
			&-item {
				justify-content: space-between;
			}
		}
		&-title {
			font-size: 18px;
			line-height: 23px;
			font-weight: 400;
			margin-bottom: 2px;
			display: flex;
			align-items: flex-start;
			@include minw($grid-breakpoints-xs) {
				font-size: 22px * .8;
				line-height: 23px * .8;
				font-weight: 700;
			}
			@include minw($grid-breakpoints-lg) {
				font-size: 22px;
				line-height: 23px;
			}
			&.last {
				@include minw($grid-breakpoints-xs) {
					width: 62px;
				}
				@include minw($grid-breakpoints-lg) {
					width: 75px;
				}
			}
			&-sup {
				font-size: 10px;
				font-weight: 400;
				line-height: 12px;
				margin-left: 4px;
				@include minw($grid-breakpoints-lg) {
					font-size: 12px;
					line-height: 14px;
				}
			}
		}
		&-sub-title,
		&-info {
			display: inline-flex;
			flex-wrap: wrap;
			font-size: 13px;
			font-weight: 400;
			color: rgba($black, .5);
			@include minw($grid-breakpoints-xs) {
				font-size: 14px;
			}
			@include minw($grid-breakpoints-lg) {
				font-size: 16px;
			}
		}
		&-info {
			align-items: center;
			color: rgba($black, .4);
			margin-top: 12px * .8;
			@include minw($grid-breakpoints-lg) {
				margin-top: 12px;
			}
			.app-icon {
				display: inline-block;
			}
		}
		&-tariff-info {
			padding: 0 5px;
			@media only screen and (min-width: $grid-breakpoints-sm) and (max-width: 1024px - 1) {
				display: none !important;
				&.mobile-show {
					display: block !important;
					.a-card-tariff-info-item-count {
						font-size: 8px;
					}
				}
			}
			&-row {
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				margin: 0 -5px;
			}
			&-col {
				padding: 0 5px;
			}
			&-item {
				display: flex;
				color: $white;
				&:hover {
					.a-card-tariff-info-item-icon {
						color: $green-persian;
					}
				}
				&.luggage {
					.a-card-tariff-info-item-count {
						bottom: 2px;
					}
				}
				&.checked {
					.a-card-tariff-info-item-count {
						bottom: 1px;
					}
				}
				&-icon {
					color: rgba($cyprus, .5);
					transition: color $transition;
					width: auto !important;
					svg {
						width: auto !important;
					}
				}
				&-count {
					font-size: 8px;
					font-weight: 700;
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
					text-align: center;
					@include minw($grid-breakpoints-xs) {
						font-size: 11px;
					}
				}
			}
		}
	}
</style>
