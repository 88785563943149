<template>
	<div class="a-timeline-transfers">
		<div class='a-timeline-item'>
			<div class='a-timeline-transfers-wrap'>
				<div class='a-timeline-transfers-image' v-if='!hideImage && item.image'>
					<img :src="item.image">
				</div>
				<div class='a-timeline-transfers-content'>
					<p class='a-timeline-transfers-title'>{{item.transferType}}</p>
					<div class='a-timeline-transfers-direction'>
						<p class='a-timeline-transfers-direction-text'>
							{{contract.pickupLocationName}}
						</p>
							<v-icon icon='arrow-right' size='12' class='a-timeline-transfers-direction-icon' :class='{"reverse": contract.transferType === "OUT"}' />
						<p class='a-timeline-transfers-direction-text'>
							{{contract.destinationLocationName}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<a-timeline-markup
			:item='item'
			:price='item.price'
			:title='title'
			:titleIcon='titleIcon'
			:result='result'
			@update-order='$emit("update-order", $event)'
			v-if='item.price.userMargin'
		/>
	</div>
</template>

<script>
	import ATimelineMarkup from '@/views/account/itinerary/markup';

	export default {
		props: ['item', 'contract', 'title', 'titleIcon', 'result', 'hideImage'],
		components: {
			ATimelineMarkup
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-transfers {
		&-wrap {
			padding: 20px 15px;
			@include minw($grid-breakpoints-xs) {
				padding: 35px 20px;
				display: flex;
				align-items: center;
			}
		}
		&-image {
			flex-shrink: 0;
			width: 100%;
			height: 170px;
			border-radius: 5px 5px 0 0;
			overflow: hidden;
			margin-bottom: 15px;
			@include minw($grid-breakpoints-xs) {
				width: 110px;
				height: 80px;
				border-radius: 5px;
				margin-right: 40px;
				margin-bottom: 0;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		&-content {
			flex: 1 1 100%;
		}
		&-direction {
			font-size: 16px;
			font-weight: 400;
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 5px;
			@include minw($grid-breakpoints-xs) {
				margin-bottom: 10px;
			}
		}
		&-direction {
			@include maxw($grid-breakpoints-xs - 1) {
				font-size: 14px;
			}
			&-text {
				display: contents;
			}
			&-icon {
				display: inline-block;
				margin: 0 5px;
				svg {
					fill: none;
					stroke: currentColor;
				}
				&.reverse {
					transform: rotate(-180deg);
				}
			}
		}
	}
</style>
