<template>
	<v-dialog :value='value' :width='700' class-content='border-radius a-timeline-dialog-time' @close-dialog="$emit('close-dialog')">
		<div class='a-timeline-dialog-time-icon'>
			<v-icon icon='info' size='46' />
		</div>
		<div class='a-timeline-dialog-time-content'>
			<p class='a-timeline-dialog-time-title'>{{$t("account.itinerary.time.title")}}</p>
			<p class='a-timeline-dialog-time-text'>{{$t("account.itinerary.time.description")}}</p>
			<div class='a-timeline-dialog-time-action'>
				<div class='a-timeline-dialog-time-col'>
					<v-button block xxsmall color='green white--text'>{{$t("account.itinerary.time.extend_time")}}</v-button>
				</div>
				<div class='a-timeline-dialog-time-col'>
					<v-button block xxsmall outline color='green'>{{$t("account.itinerary.time.to_the_suitcase")}}</v-button>
				</div>
			</div>
			<div>
				<span class='app-link green--text'>{{$t("account.itinerary.time.new_tour")}}</span>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		props: ['value', 'title']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-dialog-time {
		z-index: 5;
		padding: 45px 35px;
		display: flex;
		@include maxw( $grid-breakpoints-xs - 1 ) {
			max-width: 100% !important;
			border-radius: 0 !important;
			padding: 10px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
		&-icon {
			color: $green-persian;
			margin-right: 20px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-right: 0;
				margin-bottom: 15px;
			}
		}
		&-title {
			font-size: 20px;
			font-weight: 700;
			margin: 10px 0;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-weight: 400;
			}
		}
		&-text {
			font-size: 13px;
			font-weight: 400;
			line-height: 1.5;
		}
		&-action {
			display: flex;
			margin: 25px -8px;
			@include minw( $grid-breakpoints-xs ) {
				.app-btn {
					padding: 0 25px;
				}
			}
		}
		&-col {
			padding-right: 8px;
			padding-left: 8px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex: 0 1 100%;
			}
		}
	}
</style>
